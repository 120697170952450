/* FILE */
.file-browser {
    margin-top: 22px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .no-data {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 23px;
        img {
            transform: translateX(12px);
        }
    }
    .page-title {
        margin:20px 20px 10px 20px;
        width: calc(100% - 40px);
    }

    .button-folder {
        margin-left:20px !important;
        width: calc(100% - 40px) !important;
        padding: 10px 16px !important;
        background-color: white !important;
        color: #444444 !important;
        font-size: 14px !important;
        border-radius: 10px !important;
        margin-bottom: 8px !important;
        position:relative;
        svg {
            color:grey;
            margin-right:8px;
            font-size:18px;
            transform:translateY(-2px);
        }
        .arrow {
            color:black;
            position: absolute;
            right: 11px;
            top: 11px;
            font-size: 18px;
        }
    }

    .button-folder-back {
        margin-left: 23px !important;
        color: rgba(0, 0, 0, 0.87) !important;
        .back {
            margin-right: 13px;
        }
    }

}

.file-viewer-footbar {
    background-color: #cccc !important;
    top: auto !important;
    bottom: 0 !important;
    color: grey !important;
    position: absolute;
    width: 100vw;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    .p-button {
        color: black!important;
    }
    input {
        text-align:center;
        border:0;
        padding:3px 5px;
        width:20px;
        margin-right:5px;
    }
}

.file-viewport {
    margin-top:65px;
}

.title-folder-title {
    font-size: 20px !important;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
}

.doc-list {
    width: calc(100% - 32px) !important;
    margin: 16px !important;
}

.file-box {
    margin-top:10px !important;
    margin-left:20px !important;
    height:84px;
    background-color: white !important;
    width: calc(100% - 40px);
    border-radius: 12px !important;
    color:black !important;
    display:flex;
    justify-content: space-around;
    .icon {
        color:grey;
        font-size: 24px;
        margin-left: 10px;
    }
    .date {
        position: absolute;
        bottom: 4px;
        right: 10px;
        font-size: 12px;
    }
}

.send-picture-wrapper {
    margin-top:24px;
    display: flex;
    align-content: center;
    justify-content: center;
}