.wrapper-risk {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    margin: 1rem;
    .p-checkbox {
        margin-right: 8px;
    }
}

.add-risk-btn {
    position: absolute !important;
    top: -2rem;
    right: 0px;
    height: 2rem;
    width: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
}

.form-security-textarea {
    width: 100% !important;
    margin-left: 0% !important;
    margin-top: 8px !important;
}

.type-selector-secu {
    margin: 8px 0px 8px 0 !important;
}

.security-report-wrapper {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    .previous_label {
        margin: 16px 0 0 38px;
    }
    .no-data {
        width:100%;
        display:flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex:1;
        place-self: center;
    }
    .sr_row {
        justify-content: space-between;
        margin-top: 10px !important;
        margin-left: 20px !important;
        background-color: white !important;
        width: calc(100% - 40px);
        border-radius: 12px !important;
        color: black !important;
        display: flex;
        .update {
            font-size: 12px;
        }
        .p-button {
            text-align: left;
            display: flex;
            justify-content: center;
            color: #222222;
        }
    }
}
