.pictures-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .waitingLayer {
        text-align: center;
        font-style: italic;
        color: grey;
    }
}
.upload-image-button {
    .p-button {
        color:#222!important;
        border-radius:20px;
        background-color: white;
        svg {
            margin-right:10px;
            font-size: 20px;
        }
        margin-bottom:16px;
    }
}
.hideInput {
    opacity:0;
    position:fixed;
    z-index:-999999;
    display:block;
}
