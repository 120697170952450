@font-face {
    font-family: "Product";
    src: url("./fonts/ProductSansItalic400.ttf");
    font-style: italic;
}

@font-face {
    font-family: "Product";
    src: url("./fonts/ProductSansBoldItalic700.ttf");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Product";
    src: url("./fonts/ProductSansBold700.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Product";
    src: url("./fonts/ProductSans400.ttf");
}

html {
    height: 100%;
}

body {
    overflow: hidden;
    font-family: "Product";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hide {
    display:none;
}