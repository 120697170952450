.module-page {
    &.module-cycle {
        .no-data {
            height: calc(100% - 54px);
        }
    }
    &.module-dayoff{
        .no-data {
            height: calc(100% - 140px);
        }
    }
    .no-data {
        width:100%;
        display:flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex:1;
    }
    .page-title {
        width: calc(100% - 40px);
        margin:20px 20px 10px 20px;
    }
    .cycle-title {
        width: calc(100% - 40px);
        margin:20px 20px 10px 20px;
    }

    .button-folder {
        width: calc(100% - 40px) !important;
        margin-left: 20px !important;
        padding: 10px 16px !important;
        background-color: white !important;
        color: #444444 !important;
        font-size: 14px !important;
        border-radius: 10px !important;
        margin-bottom: 8px !important;
        position:relative;
        svg {
            color:grey;
            margin-right:8px;
            font-size:18px;
            transform:translateY(-2px);
        }
        .arrow {
            color:black;
            position: absolute;
            right: 11px;
            top: 11px;
            font-size: 18px;
        }
    }

    .button-folder-back {
        margin-left: 23px !important;
        color: rgba(0, 0, 0, 0.87) !important;
        .back {
            margin-right: 13px;
        }
    }
    .cycle-box {
        width: calc(100% - 64px);
        margin-left: 32px;
        border-radius: 16px;
        flex-direction: column;
        display: flex;
        min-height: 64px;
        text-align: left;
        box-shadow: 3px 3px 8px 0px rgba(217, 217, 217, 1);
        margin-top: 10px;
        background-color: #ffffff;
        position: relative;
        padding: 16px;
        font-size: 13px;
        font-weight: normal;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        strong {
            font-weight: 700;
            font-size: 15px;
        }
        .cycle-box-row {
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            width: 100%;
            &.row-2 {
                margin: 20px 0;
            }
            div {
                &.right {
                    text-align: right;
                }
                flex: 1 0;
            }
        }
        .see-button {
            margin-bottom: 12px;
            text-align:center;
            display: flex;
            justify-content: center;
        }
        .note,
        .save-button,
        .cycle-status-message,
        .cycle-status {
            display: flex;
            justify-content: center;
            margin-top: 12px;
        }
    }
}
