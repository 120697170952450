.pictures,
.pictures-preview {
    width: calc(100% - 64px);
    margin: 16px 32px;
    position: relative;
    padding: 16px;
    background-color: white;
    border-radius: 16px;
    img {
        width: 100%;
    }
    .react-transform-component {
        .picture-row {
            width:100%;
        }
    }
    .delete {
        color: red !important;
        position: absolute;
        top: -10px;
        right: -14px;
        background-color: white !important;
        padding: 6px;
        min-width: auto;
        border-radius: 17px;
        width: 32px;
        height: 32px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    .photo-desc {
        width: 100%;
        &::placeholder {
            color:#bbb;
            font-style: italic;
        }
    }
}

.pictures-preview {
    & > div {
        position:relative;
        div.waitingLayer {
            content:'Apperçu';
            position:absolute;
            top:0;
            left:0;
            color:black;
            font-size:20px;
            opacity: 0.4;
            width:100%;
            text-align:center;
            height:100%;
            background-color:white;
            padding:10px;
        }
    }
}