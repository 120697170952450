.error-center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ad191c;
    padding: 20px;
    box-sizing: border-box;
    color: white;
    text-align: center;
    flex-direction: column;

    div {
        margin: 2rem;
    }
}

.plus-button-module-list {
    position: fixed !important;
    transition: transform 200ms !important;
    border-radius: 25px !important;
    height: 50px !important;
    width: 50px !important;
    padding: 0px !important;
    min-width: 0px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 24px !important;
    z-index: 1200;
    bottom: 16px;
    right: 10px;

    &.overlay-shown {
        transform: rotate(45deg);
    }
}

.dialog-stop-offline {
    background-color: #222;
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    border-radius: 0 !important;

    .p-dialog-header {
        background: transparent !important;
    }

    .p-dialog-content {
        background: transparent !important;
    }

    padding: 77 0 !important;

    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text {
            margin-top: 32px;
            color: #aaa;
        }
    }
}

.offline-header {
    margin-top: 8px;
    margin-bottom: 4px;

    svg {
        background-color: #920022;
        color: white;
        padding: 8px;
        font-size: 32px;
        transform: translateY(8px);
        border-radius: 20px;
        height: 40px;
        width: 40px;
    }

    div {
        margin-left: 12px;
        display: inline;
        font-weight: bold;
    }
}

.offline-tabs {
    min-height: calc(100% - 68px);
    padding-bottom: 68px;
    display: flex;
    flex-direction: column;

    .button-save {
        svg {
            margin-right: 8px;
        }

        text-transform: none;
        font-weight: normal;
        letter-spacing: normal;
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 32px;
    }

    .p-tabview-panels {
        margin-top: 20px;
        border-radius: 32px !important;
        flex: 1;
        box-shadow: 0px 2px 10px lightgrey;
        background-color: #f4f5f8 !important;

        .select-please {
            margin-left: 20px;
            color: grey;
            margin-bottom: 7px;
        }

        .select-list {
            color: grey;
            margin: 16px 21px 0 21px;
            font-size: 14px;
        }

        .selection-list {
            background-color: white;
            border-radius: 20px;
            min-height: 32px;
            margin: 16px;
            box-shadow: 0px 0px 8px 0px lightgrey;

            &>.row {
                align-items: center;
                display: flex;
                color: #505050;
                padding: 16px;

                &:not(:last-child) {
                    border-bottom: 1px solid #e7e7e7;
                }

                div:first-child {
                    flex: 8;
                }

                div:last-child {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    button {
                        font-size: 20px;
                        align-items: center;
                        justify-content: center;
                        color: #cd002f;
                    }
                }
            }
        }

        .p-multiselect {
            border: 0 !important;
        }
    }

    .p-tabview-nav {
        justify-content: space-around;
        border: 0 !important;
        width: 100%;
        background: transparent !important;

        .p-tabview-title {
            color: grey;
        }

        &>li {
            align-items: center;
            justify-content: center;
            text-align: center;

            .p-tabview-nav-link {
                width: 100;
                display: block;
                background: transparent !important;
            }

            img {
                width: 32px;
                filter: brightness(0);
                opacity: 0.5;
            }

            svg {
                font-size: 28px;
                color: grey;
            }

            &.p-tabview-selected {
                .p-tabview-title {
                    color: black;
                }

                img {
                    opacity: 1;
                }

                svg {
                    font-size: 28px;
                    color: black;
                }
            }
        }
    }

    .p-tabview-ink-bar {
        height: 6px !important;
        border-radius: 3px !important;
        background-color: #fbc601 !important;
    }
}

.header {
    background-color: #222222;
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    color: white;
    height: 54px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    padding-left: 56px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;

    .offlineicon {
        position: absolute;
        top: 16px;
        left: 56px;
        font-size: 18px;
        color: #970009;
    }

    .username {
        margin-top: 10px;
    }

    .agency {
        margin-top: 2px;
        font-size: 11px;
        font-weight: normal;
    }

    .topnavbar {
        padding-right: 12px;

        .offline-info {
            font-size: 14px;
            float: left;
            line-height: 54px;
            color: #970009;
        }

        .nav-button {
            float: right;
            color: white;
            font-size: 24px;
            display: flex;
            flex-flow: column;
            padding: 12px 20px 12px 20;
            min-width: 0px;

            &.site {
                img {
                    width: 27px;
                }
            }

            &.active {
                color: #fbc601;
            }

            &.button-alert {
                position: relative;

                .button-alert-number {
                    position: absolute;
                    bottom: 22px;
                    right: 14px;
                    background: red;
                    border-radius: 15px;
                    font-size: 10px;
                    color: white;
                    width: 14px;
                    height: 14px;
                    font-weight: bold;
                }
            }
        }
    }

    &.offlinemode {
        background-color: #fff;
        color: black !important;
        // display:flex;
        // justify-content: space-between;
        // flex-direction: row;
        padding-left: 90px;

        .topnavbar {
            .nav-button {
                color: black !important;

                &.site {
                    filter: brightness(0);
                }

                &.active {
                    color: #fbc601 !important;

                    &.site {
                        filter: brightness(1);
                    }
                }
            }
        }
    }
}

.offlinemode.module-app {

    .bm-burger-bars,
    .bm-burger-bars-hover {
        background-color: black !important;
    }
}

.module-app {
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url("../../../public/auth-illustration.jpg");
    background-size: cover;
    background-position: 70% 60%;
    position: fixed;

    .bm-menu {
        overflow: hidden !important;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-button {
        position: fixed;
        width: 28px;
        height: 22px;
        left: 14px;
        top: 15px;
    }

    .bm-burger-bars,
    .bm-burger-bars-hover {
        background-color: white;
    }

    .bm-menu-wrap {
        background-color: white;
        border-radius: 0 32px 32px 0;
        /* Position and sizing of burger button */

        /* Position and sizing of clickable cross button */
        .menu-icon {
            display: inline-block;
            width: 30px;
            text-align: left;
        }

        svg {
            font-size: 20px;
        }

        .bm-cross-button {
            height: 36px;
            width: 36px;
            left: 15px !important;
            top: 14px !important;
            right: auto !important;

            &>span {
                top: 8px !important;
                right: 19px !important;
            }
        }

        /* Color/shape of close button cross */
        .bm-cross-button {
            background-color: #323232;
            border-radius: 18px;
            width: 36px !important;
            height: 36px !important;
        }

        .bm-cross {
            background-color: white;
            border-radius: 12px;
            height: 20px !important;
        }

        .btn-homepage {
            margin-top: 16px;
            margin-left: 60px;
            font-size: 12px;
            font-weight: bold;
            color: black;
        }

        .username {
            margin-top: 16px;
            margin-left: 60px;
            font-size: 12px;
            font-weight: bold;
            color: black;
        }

        .agency {
            color: black;
            margin-top: 2px;
            margin-left: 60px;
            margin-bottom: 16px;
            font-size: 11px;
            font-weight: normal;
        }

        .menu-button {
            color: black !important;
            font-weight: 400;
            font-size: 16px;

            &.p-disabled {
                color: lightgray !important;
                background-color: transparent !important;
            }

            &.button-disconnect {
                i {
                    margin-right: 20px;
                    transform: translateY(2px);
                }

                color: red !important;
            }
        }

        .activate-offline-mode {
            bottom: 64px;
            position: absolute;
            font-size: 12px;
            margin: 0 20px;
            color: #aaa;
        }

        .storeSize {
            position: absolute;
            bottom: 38px;
            font-style: italic;
            font-size: 14px;
            color: grey;
            width: 100%;
            text-align: center;
        }

        .package-version {
            font-style: italic;
            display: block;
            bottom: 18px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            font-size: 12px;
            color: #aaa;
        }
    }

    .module-list {
        width: 75%;
        margin-left: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        height: 100%;

        .choose_a_module {
            width: 100%;
            color: white;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .module-button {
            .icon {
                width: 33%;
                color: #fbc601;
                font-size: 46px;
                text-align: center;
                padding-right: 16px;

                &.icon-site {
                    img {
                        width: 52px;
                        margin-top: 8px;
                    }
                }
            }

            .text {
                color: #222222;
                width: 66%;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .desc {
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            height: 104px;
            background-color: white !important;
            width: 100%;
            border-radius: 20px 0 0 20px;
            margin: 6px 0;
            text-align: left;
            color: black !important;
        }
    }
}

.day-picker {
    width: calc(100%);
    justify-content: center;
    align-items: center;
    background-color: white !important;
    border: 1px solid #fbc601;
    border-radius: 12px;

    .currentDay {
        width: calc(100% - 24px);
        margin-left: 12px;
        text-align: center;
        font-size: 14px;

        input {
            height: 32px;
        }
    }

    .p-disabled {
        background-color: transparent !important;
    }
}


.day-navigation {
    display: flex;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    background-color: white !important;
    border: 1px solid #fbc601;
    border-radius: 12px;
    margin-top: 20px;

    .currentDay {
        width: calc(100% - 40px);
        text-align: center;
        font-size: 14px;
    }

    .p-button {
        justify-content: center;
        color: #fbc601 !important;
        height: auto !important;

        &.p-disabled {
            color: grey !important;
            background-color: transparent !important;
        }
    }

    .p-disabled {
        background-color: transparent !important;
    }
}

.footbar {
    background-color: #222222;
    height: 81px;
    position: absolute;
    bottom: 0;
    border-radius: 20px 20px 0 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .plus-button-footbar {
        transition: transform 200ms;
        border-radius: 25px;
        height: 50px;
        width: 50px;
        padding: 0px;
        min-width: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 24px;
        z-index: 1200;

        @media screen and (max-width: 400px) {
            position: absolute;
            bottom: 100px;
            right: 8px;
        }

        &.overlay-shown {
            transform: rotate(45deg);
        }
    }

    .p-button-link {
        overflow: visible;
        width: 80px;
        height: 80px;
        color: #aaaaaa !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.selected {
            color: white !important;
        }

        &.selected::before {
            content: "";
            height: 8px;
            width: 64px;
            border-radius: 10px;
            background-color: #fbc601;
            position: absolute;
            top: -6px;
        }

        .icon {
            font-size: 30px;
        }

        .text {
            font-size: 12px;
        }
    }
}

.moduleSelector {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 54px;

    .userDescription {
        flex: 1;
        padding: 20px 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .userInfo {
            position: relative;
            user-select: none;

            .initials {
                width: 72px;
                height: 72px;
                background: #e4e4e4;
                border-radius: 36px;
                text-align: center;
                font-size: 38px;
                padding-top: 14px;
                margin: auto 50%;
                transform: translateX(-50%);
            }

            .name {
                margin-top: 5px;
                color: white;
                font-weight: bold;
                font-size: 12px;
                text-align: center;
            }

            .agency {
                color: white;
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .userModule {
        flex: 3;
        background-color: #e7eaef;
        border-radius: 20px 20px 0 0;
        overflow-y: auto;
        padding-top: 30px;

        .userSelection {
            position: absolute;
            transform: translateY(-50%);
            margin-top: -30px;
        }

        .module-button {
            z-index: 0;
            margin-top: 11px;
            background-color: white !important;
            width: 80%;
            margin-left: 20%;
            border-radius: 20px 0 0 20px;
            text-align: left;
            color: black !important;
            display: grid;
            grid-template-columns: 30% 1fr;

            .icon {
                color: #fbc601;
                font-size: 46px;
                text-align: center;
                padding-right: 16px;
                display: flex;
                justify-content: center;
            }

            .text {
                color: #222222;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .desc {
                    margin-top: 6px;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
}

.overlay-creation {
    width: 100%;
    height: 100%;
    // background-color: #373737;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1100;
    position: fixed;
    top: 0;
    left: 0;
    display: none;

    &.show {
        display: block;
    }

    .action-zone {
        position: fixed;
        bottom: 52px;
        width: 100%;
        padding: 32px;
        display: flex;
        flex-direction: column;

        .label {
            font-size: 14px;
            margin: 0 0 0px 16px;
            color: #eeeeee;
        }

        .p-button,
        .p-button:hover,
        .p-button:active,
        .p-button:visited {
            text-align: left;
            background: black;
            margin-top: 16px;
            border: 1px solid #404040;
            border-radius: 10px;
            font-size: 16px;
            font-weight: bold;

            svg {
                font-size: 20px;
                color: grey;
                margin-right: 12px;
            }

            &.p-disabled {
                color: gray !important;
            }
        }
    }
}

.create-dialog {
    margin: 0 !important;
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 54px;
    background: #f1f2f6 !important;

    .add_the_deposit_ticket {
        top: calc(32px);
        height: 64px;
        font-weight: bolder;
    }

    .add_the_site_ticket {
        top: calc(32px);
        height: 64px;
        font-weight: bolder;
    }

    .p-dialog-header {
        display: none;
    }

    .p-dialog-content {
        background: #f1f2f6 !important;

        .topnavbar {
            &.form-event {
                display: flex;
                justify-content: space-between;
            }

            .close-button {
                position: absolute;
                left: 4px;
                font-size: 20px;
                top: 7px;
                color: white;
            }

            .title {
                display: flex;
                align-items: center;
                font-size: 12px;
                text-transform: uppercase;
                float: left;
                height: 54px;
                padding-right: 8px;
            }

            .create-event-button {
                float: right;
                color: white;
                font-size: 20px;
                display: flex;
                flex-flow: column;
                padding: 16px 0 12px 0;
                min-width: 0px;
                width: 64px;

                &.active {
                    color: #fbc601;
                }
            }
        }

        .event-type-desc {
            display: flex;
            margin-top: 12px;

            .icon {
                height: 40px;
                width: 40px;
                background-color: white;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-content: center;

                img {
                    margin-top: 9px;
                    height: 19px;
                }
            }

            .text {
                font-size: 16px;
                font-weight: bold;
                margin-top: 9px;
                margin-left: 8px;
            }
        }

        .panel {
            width: 100%;
            justify-content: space-evenly;
            align-content: flex-start;
            margin-top: 16px;
            margin-bottom: 18px;
            row-gap: 24px;
            display: flex;
            &.one-child {
                display: grid;
                grid-template-columns: 1fr;
            }
            &.two-child {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            &.three-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
            &.four-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            &.five-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
            &.six-child {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                @media screen and (max-width: 500px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }

            >.p-button-link {
                color: #7d7d7d !important;
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: unset;
                padding: 0;

                .icon {
                    font-size: 24px;
                }

                &.selected {
                    color: black !important;
                }

                &.missing {
                    color: #b00020 !important;
                }

                &.p-disabled {
                    background: transparent !important;
                    color: #ccc !important;
                }

                &.selected::after {
                    content: "";
                    position: absolute;
                    height: 6px;
                    border-radius: 3px;
                    width: 100%;
                    background: #fbc601;
                    bottom: -12px;
                }
            }

            .text {
                font-size: 12px;
            }
        }

        .note {
            width: 100%;
            margin: 12px 0;
        }

        .label {
            width: calc(100% - 40px);
            margin: 10px 16px;

            &.p-invalid {
                color: #b00020;
            }
        }

        .siteCode {
            display: flex;
            justify-content: center;
            align-content: center;
            height: 50px;
            align-items: center;
            width: 300px;
            margin: 18px auto;
            font-weight: bolder;
        }

        .depositCode {
            display: flex;
            justify-content: center;
            align-content: center;
            height: 50px;
            align-items: center;
            width: 300px;
            margin: 18px auto;
            font-weight: bolder;
        }

        .block-pictures {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .formSiteTicketWrapper>,
        > {

            .p-inputtext,
            .p-inputnumber,
            .p-dropdown,
            .p-multiselect {
                border-radius: 5px;
                width: calc(100% - 40px);
                margin-left: 20px;
                margin-bottom: 10px;
            }
        }

        .formSiteTicketWrapper {
            .order-ref {
                padding: 8px 16px;
                margin: 0 16px;
                border-radius: 8px;
            }

            background-color: #f4f5f8;
            padding: 16px 8px;
            border-radius: 16px;

            .site-information,
            .p-dropdown,
            .p-input {
                background-color: white;
            }

            .article-list {
                margin-top: 32px;
            }
        }

        .article-list {
            .select_title {
                margin-left: 5%;
                color: rgba(0, 0, 0, 0.6);
                padding: 0px;
            }

            .available-articles {
                display: flex;
                flex-direction: column;

                .p-multiselect {
                    border-radius: 15px;
                    width: 90%;
                    margin: 0px 5% 10px 5%;
                }
            }

            .selected-article-list {
                margin: 10px;
                border-radius: 20px;
                padding: 1px 10px;
                background: white;
            }
        }

        .tabEvent,
        .tabInfos,
        .tabTimeslot,
        .tabOptions,
        .tabIntervention {
            position: relative;
            background: #f4f5f6;
            border-radius: 20px;
            margin-top: 20px;
            padding-top: 2px;
            min-height: 80px;
            padding-bottom: 1rem;

            // .upload-image-button {
            //     top: 8px;
            //     right: 16px;
            // }
            &.tabOptions {
                padding: 16px;
                color: #222222;
            }

            .event-options-error {
                margin: 20px 5%;
                color: #b00020;
                font-size: 14px;
                font-style: italic;
            }

            .p-error {
                margin-left: 5%;
            }

            .selected-prestation-list {
                margin: 10px;
                border-radius: 20px;
                padding: 1px 1px 1px 10px;
                background: white;

                .prestation-row {
                    margin: 10px 0;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    align-content: center;
                    flex: 1;

                    .title {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .category {
                            color: #7d7d7d;
                            font-size: 14px;
                        }
                    }

                    .remove {
                        margin-left: 10px;
                        margin-right: 5px;
                        padding: 0;
                        flex: 0 0;
                        min-width: 32px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        color: #6b6f73 !important;
                    }

                    .prestation-input-qt {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        justify-content: flex-end;

                        .prestation-button {
                            border: 1px solid #fbc601 !important;

                            &,
                            &:active,
                            &:hover {
                                color: #fbc601 !important;
                            }

                            min-width: 0;
                            padding: 8px;
                            margin: 0px;
                        }

                        .p-inputtext {
                            background-color: transparent;
                            border: 0;
                            width: 64px;
                            padding: 8px;
                            text-align: center;
                        }
                    }

                }
            }

            .available-prestation {
                display: flex;
                flex-direction: column;

                .p-multiselect {
                    border-radius: 15px;
                    width: 90%;
                    margin: 0px 5% 10px 5%;
                }
            }

            .signatory {
                margin: 0 5%;
                width: 90%;
            }

            .sigCanvas {
                display: flex;
                justify-content: center;
                align-content: center;
                margin: 16px 0;

                canvas {
                    border: 1px solid #222222;
                    background-color: #dedede;
                    width: 100%;
                }
            }

            .signature-actions {
                display: flex;
                justify-content: space-around;
                align-content: center;
                padding: 0px 0 14px 0;
            }

            .option {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                padding: 10px;

                .label,
                .setter {
                    display: inline-block;
                    width: 50%;
                }

                .p-inputtext {
                    width: 100%;
                }
            }

            .timeslotname {
                font-size: 20px;
                font-weight: bold;
                margin-left: 5%;
                color: rgba(0, 0, 0, 0.6);
                padding: 0px;
            }

            .select_title {
                margin-left: 5%;
                color: rgba(0, 0, 0, 0.6);
                padding: 0px;
            }

            &.tabInfos {
                font-size: 15px;
                text-align: center;
                padding: 10px 10px 20px 10px;

                .select_title {
                    margin-left: 0px !important;
                }
            }

            .search-field {
                width: 200px;
            }

            >.p-dropdown {
                border-radius: 5px;
                width: 90%;
                margin-left: 5%;
                margin-bottom: 10px;
            }

            .event-duration {
                margin: 0 5%;
                display: flex;
                justify-content: space-evenly;

                .p-dropdown {
                    flex: 1;

                    .p-dropdown-label {
                        text-align: center;
                    }

                    .p-dropdown-trigger {
                        display: none;
                    }
                }

                .separator {
                    margin: 0 10px;
                    display: flex;
                    align-items: center;
                }
            }

            .add-workshop {
                margin: 8px 20px 20px 20px;
                padding-top: 20px;
                color: rgba(0, 0, 0, 0.6);

                .p-checkbox {
                    margin-right: 8px;
                }

                border-top: 1px solid rgba(0, 0, 0, 0.87);
            }

            .wholeday {
                margin: 20px;
                color: rgba(0, 0, 0, 0.6);

                .p-checkbox {
                    margin-right: 8px;
                }
            }

            .note {
                width: 90%;
                margin: 0 5% 20px 5%;
            }
        }
    }

    .site-information {
        padding: 16px;
        margin: 16px 12px;
    }
}

.type-selector {
    margin: 10px 20px;
    display: flex;
    justify-content: space-around;
    background-color: #e7eaef;
    border-radius: 16px;

    div {
        background-color: #e7eaef;
        flex: 1;
        text-align: center;
        padding: 10px;
        color: #8b8c91;
        font-size: 14px;
        justify-content: center;
        border: 2px solid transparent;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        &:first-child {
            border-radius: 10px 0 0 10px;
            margin-left: 0px;
        }

        &:last-child {
            border-radius: 0 10px 10px 0;
            margin-right: 0px;
        }

        flex: 0;

        &.selected {
            flex: 1;
            color: black;
            background: white;
            border: 2px solid #fcdd00;
            border-radius: 10px;
        }

        svg {
            font-size: 20px;
            margin-right: 10px;
            margin-bottom: -2px;

            &.valid {
                color: #fbc601;
            }
        }
    }
}

.p-error.p-d-block {
    margin: auto 20px;
    width: calc(100% - 40px);
}

.article-groups {
    margin-top: 20px;
}

.button-add-article {
    width: calc(100% - 40px) !important;
    margin-left: 20px !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s !important;
    color: grey !important;
    height: 50px !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 20px !important;
}

.article-row {
    margin: 10px 0;
    height: 66px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;

    .title {
        flex: 5;
        font-size: 14px;
    }
}

.counter {
    margin-left: 4px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;

    .p-inputtext {
        background-color: transparent;
        border: 0;
        width: 64px;
        padding: 8px;
        text-align: center;
    }

    .counter-button {
        border: 1px solid #fbc601 !important;

        &,
        &:active,
        &:hover {
            color: #fbc601 !important;
        }

        min-width: 0;
        padding: 8px;
        margin: 0px;
    }

    .delete-button {
        padding: 0;
        flex: 0 0;
        min-width: 32px;
        display: flex;
        justify-content: flex-end;
        color: #222 !important;
    }
}

.article-filter {
    margin-top:8px;
    width: 100%;
    input {
        padding-left: 48px;
    }
    i {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.help-file {
    position: fixed !important;
    bottom: 0;
    left: 0;
    font-size: 40px !important;
    color: white !important;
}