@import "../files/style.scss";

.module-materials {
    flex: 1;
    overflow: scroll;

    .select-material {
        margin-left: 16px;
        width: calc(100% - 32px);
        margin-top: 16px;

        .input-filterable {
            width: 100%;
        }
    }

    .modules {
        width: 100%;
        margin: 20px auto;

        .module {
            height: 220px;

            button {
                display: block;
                width: 100%;
                padding: 0;

                .icon {
                    background-color: #ffcb08;
                    color: white;
                    padding: 32px;
                }

                .text {
                    height: 98px;
                    font-size: 16px;
                    text-transform: none;
                    padding: 8px 4px 0px 4px;

                    .desc {
                        font-size: 12px;
                        font-weight: normal;
                        color: #333;
                    }
                }
            }
        }
    }

    .section_note {
        margin-left: 32px !important;
    }

    .selector {
        width: calc(100% - 40px);
        display: flex;
        margin-top: 20px;

        .selector-choice {
            &:hover {
                background-color: #ad191c;
                color: white;
            }

            padding: 10px;
            flex: 1;
            border-top: 2px solid #ad191c;
            border-bottom: 1px solid #ad191c;
            background-color: #ad191c00;
            color: black;
            transition: 200ms;
            text-align: center;
            cursor: pointer;
            border-right: 1px solid #ad191c;

            &.active {
                color: white;
                background-color: #ad191c;
            }

            &:first-child {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border-left: 1px solid #ad191c;
            }

            &:last-child {
                border-right: 1px solid #ad191c;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
}

.material-info {
    .titre {
        padding: 8px 24px 0 24px;
        font-size: 18px;
    }

    .mat_form {
        margin: 16px 24px;
        background-color: white;
        border-radius: 16px;

        table {
            width: 100%;

            tr {
                width: 100%;

                td {
                    width: 50%;
                    vertical-align: top;
                }
            }
        }

        hr {
            margin: 4px 20px 4px 20px;
        }

        .mat_form_cell {
            display: inline-block;
            padding: 8px 16px;

            .material-info-titre {
                color: grey;
                font-size: 14px;
            }
        }
    }
}

.material-event-container {
    margin-top: 16px;

    .event {
        margin-top: 10px !important;
        margin-left: 20px !important;
        background-color: white !important;
        width: calc(100% - 40px);
        border-radius: 12px !important;
        color: black !important;
        padding: 8px 16px;
        display: block;
        overflow: initial;
        z-index: 0;

        .delete {
            color: red !important;
            position: absolute;
            top: -10px;
            right: -14px;
            background-color: white !important;
            padding: 6px;
            min-width: auto;
            border-radius: 17px;
            width: 32px;
            height: 32px;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }

        .odometer {
            text-align: right;
            margin-bottom: 4px;
        }

        .site {
            margin-top: 4px;
            margin-bottom: 4px;
            text-align: left;

            img {
                filter: brightness(0);
                width: 18px;
                margin-left: -2px;
                margin-right: 6px;
            }
        }

        .desc {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .site,
            .user,
            .date {
                margin-bottom: 4px;
                text-align: left;
            }
        }
    }
}

.material-info-wrapper {
    .module-page {
        padding-top: 0px !important;
    }
}

.wrapper-odometer {
    display: flex;
    margin: 16px;

    &>div {
        flex: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-left: 8px;
        text-align: left;
    }

    &>input {
        flex: 1;
        width: 100%;
        text-align: right;
    }
}

.material-shown {
    background-color: white !important;
    padding: 8px 16px;
    border-radius: 16px;
    margin: 24px 8px 16px 8px;
}

.comment-wrapper {
    width: calc(100% - 8px);
    margin-left: 4px;

    .comment {
        width: calc(100% - 10%);
        margin-left: 5%;
    }
}
