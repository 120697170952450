.module-event {
    background-color:#F2F3F7;
    min-height: 100%;
    padding-top:54px;
    padding-bottom:88px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .userSelection {
        width: calc(100% - 40px);
        margin-left: 20px;
        .p-dropdown {
            width: 100%;
            border-radius:6px;
            background-color: white;
            font-weight: bold;
        }
    }
    .label-employee {
        margin: 10px 20px;
        color: #8B8C91;
    }
    .no-data {
        flex:1;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translateX(-32px);
        .label {
            text-align: center;
            color: #8B8C91;
        }
        img {
            margin-left: calc(50% - 162px);
            margin-top: 24px;
        }
    }
    .add-event {
        position:fixed;
        left:50%;
        transform:translateX(-50%);
        bottom: 100px;
        letter-spacing: initial !important;
        border-radius:25px;
        font-weight: 400 !important;
        text-transform: initial !important;
    }
}

.pointage-validation {
    font-size:18px;
    margin: 17px 20px -14px 20px;
    background-color:white;
    padding:10px;
    border-radius:6px;

}

.event-data {
    flex:1;
    overflow: auto;
    .planning {
        background: white;
        box-sizing: border-box;
        width: 90%;
        margin-left: 5%;
        border-radius: 16px;
        &.week-list {
            background: inherit;
        }
        .dayOfWeek {
            background-color:#E7EAEF;
            margin-bottom:20px;
            border-radius: 16px;
            .dayLabel {
                padding: 16px 12px;
                font-weight: bold;
            }
            .events {
                background:white;
                border-radius: 16px;
            }
        }
        .event-list-row {
            color:#222222;
            font-size:12px;
            display:flex;
            padding: 20px 10px 20px 10px;
            border-bottom: 1px solid #dedede;
            position:relative;
            .event-details {
                .status {
                    padding: 2px 10px;
                    border-radius: 10px;
                    text-transform: uppercase;
                    transform: translateY(6px);
                    &.planned {
                        color: #F23A3A !important;
                        background-color: #FEE9E9 !important;
                    }
                    &.filled {
                        color: #FF9D00 !important;
                        background-color: #FFF4E2 !important;
                    }
                    &.signed {
                        color: #0C9CEE !important;
                        background-color: #E3F4FD !important;
                    }
                    &.validated {
                        color: #16C79A !important;
                        background-color: #E5F9F4 !important;
                    }
                }
                flex:1;
                margin-right:8px;
                position:relative;
                border-right: 2px solid #dedede;
                padding-right:8px;
                white-space: nowrap;
                svg {
                    margin-bottom: -1px;
                    margin-right: 6px;
                }
            }
            .title {
                flex:4;
                svg {
                    margin-bottom: -1px;
                    margin-right: 6px;
                }
                .workshop {
                    color:#8b8c91;
                    padding: 0;
                    margin: 0;
                }
            }
            .icon {
                flex:1;
                display: flex;
                justify-content: center;
                align-content: center;
                filter:grayscale(1);
                img {
                    width: 20px;
                }
            }
        }
        .sub-footbar {
            color:#222222;
            font-size:12px;
            display:flex;
            padding: 20px 10px 12px 10px;
            background-color:#E7EAEF;
            border-radius: 0 0 16px 16px;
            &.week {
                border-radius: 16px;
            }
            .event-duration {
                flex:1;
                margin-right:8px;
                position:relative;
                border-right: 2px solid #dedede;
                padding-right:8px;
                white-space: nowrap;
                svg {
                    color: #FBC601;
                    margin-bottom: -1px;
                    margin-right: 6px;
                }
            }
            .title {
                flex:4;
                font-size:14px;
            }
            .p-button {
                margin-top:-11px;
                color: black;
                background: white;
                border-radius: 20px;
            }
        }
    }
}

.overlay-create-event {
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
    z-index:1100;
    position:fixed;
    top:0;
    left:0;
    margin:0 !important;
    .p-dialog-header {
        display:none;
    }
    .p-dialog-content {
        padding: 0 !important;
        background: transparent !important;
        color:white;
    }
    .close {
        top:0px;
        left:0px;
        color:white !important;
        position:absolute;
    }
    .day-navigation {
        border:1px solid white !important;
        width:85% !important;
        position:absolute !important;
        left:7.5% !important;
        bottom: 278px !important;
        color: white !important;
        background-color: transparent !important;
        margin-left: 0 !important;
        margin-bottom: 0 !important;
    }
    .currentDay {
        width: calc(100% - 40px);
        text-align: center;
        font-size:14px;
        input {
            color: white;
            background: transparent;
            border-bottom: 0px;
        }

    }
    .event-selector {
        display: flex;
        justify-content: space-evenly;
        align-content: flex-end;
        position: fixed;
        width: 100%;
        bottom: 162px;
        > .p-button {
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 15px;
            &.p-disabled {
                color:#777!important;
            }
            .icon {
                height:50px;
                width: 50px;;
                background-color:black;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-content: center;
                &.disabled {
                    filter:grayscale(1);
                }
                img {
                    margin-top:13px;
                    height:20px;
                }
            }
        }
    }
}

.no_options {
    text-align: center;
    margin: 14px;
    color: #555;
    font-style: italic;
}
