.toast-site-ticket-created {
    margin-top: 2px !important;
    font-weight: bold !important;
    text-align: center !important;
}

.order_lines {
    padding: 4px 16px;
    margin: 0px 16px;
    border-radius: 16px;
    background: white;
    font-size: 12px;

    .meta-button {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .p-button {
            width: 100%;

            &,
            &:hover,
            &:active {
                color: #222 !important;
                border-radius: 20px;
                background-color: #fbfbfb;
                margin: 8px;
            }

            svg {
                margin-right: 10px;
            }
        }
    }

    .order_line {
        padding: 10px 0;

        .top_order_line {
            display: flex;
            margin-bottom: 8px;

            .order_line_label {
                flex: 1;
            }

            .order_line_qte {
                flex: 1;
                text-align: center;
            }
        }

        .bottom_order_line {
            display: flex;

            .order_line_checkboxes {
                >div {
                    margin: 2px 0;
                }

                .p-checkbox {
                    margin-right: 8px;

                    .p-checkbox-box {
                        border: 1px #fbc601 solid;

                        &.p-highlight {
                            .p-checkbox-icon::before {
                                content: "" !important;
                                position: absolute !important;
                                top: 6px !important;
                                left: 1px !important;
                                border-right: 2px solid #fbc601 !important;
                                border-bottom: 2px solid #fbc601 !important;
                                //transform: rotate(45deg) !important;
                                transform-origin: 0% 100% !important;
                                animation: checkbox-check 125ms 50ms linear forwards !important;
                            }
                        }
                    }
                }

                flex: 1;
            }

            .order_line_counters {
                flex: 1;
            }
        }
    }
}

.formSiteTicketWrapper {
    .article-row {
        flex-direction: column !important;

        .title {
            margin-left: 4px;
        }

        .counter {
            justify-content: space-between !important;
            .edit-button {
                margin-left:8px;
            }
            .edit-button, .delete-button {
                padding: auto 16px !important;
                min-width: 32px !important;
                justify-content: center !important;
                color: black;
            }
        }
    }
}
.create-site-ticket-text {
    .actions-btn {
        margin:1rem auto 0rem auto;
        display: flex;
        justify-content: space-evenly;
        button {
            display: flex;
            justify-content: center;
        }
    }
}