.module-auth {
    .img-auth {
        background-image: url('../../../public/auth-illustration.jpg');
        background-size: cover;
        background-position: center;
        position: fixed;
        top: 0;
        left: 0;
        height: 42%;
        width: 100%;
    }
    .auth-panel {
        form {
            height: 100%;
            >div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
        }
        padding:60px 50px;
        color: #B1B1B1;
        .p-field {
            margin-bottom: 1rem;
            label {
                margin-left: 16px;
            }
        }
        background-color: white;
        position:absolute;
        height:60%;
        // bottom:calc(-60% - 50px);
        bottom: 0;
        border-radius:20px 20px 0 0;
        // animation-name: show-form;
        // animation-duration: 400ms;
        // animation-timing-function: ease;
        // animation-delay: 1s;
        // animation-iteration-count: 1;
        // animation-fill-mode: forwards;
        width: 100%;
        .app-logo {
            position: absolute;
            left: 50%;
            top: 0px;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
        }
        .welcome-text {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -128px);
            color: white;
            font-size: 2rem;
            white-space: nowrap;
        }
    }
    // @keyframes show-form {
    //     from { bottom: calc(-60% - 50px); }
    //     to   { bottom: 0%; }
    // }
}