.form-attachment-comment-zone {
	textarea {
		margin: 0 5%;
		width: 90%;
		height: 150px;
	}
}

.create-dialog.attachment-variant {
	padding-top:0 !important;
	width: calc(100% - 32px) !important;
	margin-left:16px !important;
	height: auto !important;
	.selected-prestation-list {
		.prestation-row {
			margin: 1rem 0 !important;
			flex-direction: column !important;

			.title {
				flex-direction: row !important;
				justify-content: space-between !important;
				margin-bottom: 8px;
			}
		}

		.prestation-pivot {
			margin-top: 8px;
			display: flex;
			justify-content: space-between !important;
			flex-direction: row !important;
			gap: 12px;
		}
	}
	.attachment-actions {
		display: flex;
		flex-direction: row;
		margin: 2rem 0;
		gap:2rem;
	}
}