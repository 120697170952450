.p-button.flashy {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    background: linear-gradient(0.25turn, #FBC601, #fc9b00);
    color: black;
    padding: 0.8rem;

    &.valid {
        margin-top: 1rem;
    }
    &:disabled {
        background: inherit;
        &.plus-button-footbar {
            background: #3E3E3E !important;
        }
    }
    &:hover,
    &:focus {
        color: black !important;
        background: linear-gradient(0.25turn, #ffd84c, #ffbc51) !important;
    }
}

.p-button:hover,
.p-button:active
 {
    color: grey;
}

.p-multiselect-panel {
    width:calc(100% - 64px) !important;
    left: 32px !important;
    .p-multiselect-close {
        display: none;
    }
    .p-multiselect-items {
        .p-multiselect-item {
            font-size:14px;
            white-space: break-spaces;
        }
    }
}

.p-confirm-dialog {
    width: calc(100% - 40px);
    .p-dialog-header {
        display:none;
    }
    .p-dialog-content {
        padding-top: 22px;
        border-radius: 4px 4px 0 0;
    }
}

.p-button.circle-action{
    background: linear-gradient(0.5turn, #FBC601, #fc9b00);
    color: black;
    padding: 0;
    height:64px;
    width:64px;
    border-radius: 32px;
    font-size:38px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 16px;
    bottom: 16px;
    &:disabled {
        background: inherit;
    }
    &:hover,
    &:focus {
        color: black !important;
        background: linear-gradient(0.25turn, #ffd84c, #ffbc51) !important;
    }
}

.p-component {
    font-family: 'Product';
}

.p-dropdown-panel {
    // position: fixed !important;
    // width:100vw;
    // top:54px !important;
    // padding-top:54px;
    .p-dropdown-items-wrapper {
        // max-height: calc(100vh - 80px) !important;
        // height: calc(100vh - 80px) !important;
    }
}

.p-dropdown {
    color: #3E3E3E;
    background-color: #F9F9F9;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px #E6E6E6 solid;
    border-left: 0px;
    border-radius: 0px;
    &:focus,
    &:hover {
        box-shadow: none !important;
        border-bottom: 2px #3E3E3E solid !important;
    }
}

.p-inputtext {
    padding: 0.8rem;
    color: #3E3E3E;
    background-color: #F9F9F9;
    border-top: 0px;
    border-right: 0px;
    border-bottom: 2px #E6E6E6 solid;
    border-left: 0px;
    border-radius: 0px;
    &:focus,
    &:hover {
        box-shadow: none !important;
        border-bottom: 2px #3E3E3E solid !important;
    }
}

.p-multiselect {
    margin-left: 20px;
    width: calc(100% - 40px);
}

.p-dialog-maximized {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
}

.p-dropdown-item {
    white-space: normal !important;
    padding: 0.9rem 1rem;
    font-size:13px;
}

.Toastify__toast {
    margin-bottom:1rem;
    margin-left:1rem;
    margin-right:1rem;
    border-radius:10px;
    min-height: 48px;
}

.Toastify__toast-container {
    // bottom:0px;
}

.p-calendar {
    .p-inputtext {
        text-align: center;
        font-size:14px;
    }
}

.p-datepicker {
    left: 20px !important;
    width: calc(100% - 40px) !important;
}