@import '../files/style.scss';

.article-list {
    position:relative;
    background:#f4f5f6;
    border-radius:20px;
    margin-top:20px;
    margin-bottom: 20px;
    padding-bottom: 2px;
    padding-top: 2px;
    min-height: 80px;
}

.no-subpage {
    height:100%;
}

.consumption {
    margin: 10px 20px;
}

.dropdown-row {
    .code {
        color:#555;
        font-size:12px;
    }

}

.filter-desabler {
    flex-direction: row;
    display:flex !important;
    width: 100%;
    padding: 16px;
    & > button {
        flex: 1;
        padding:4px;
        margin-right:4px;
    }
    & > div {
        flex: 1;
        margin-left:4px;
    }
    .p-input-icon-right {
        width:100%;
        .p-inputtext {
            width:100%;
        }
    }
    button {
        width:100%;
    }
}

.module-supply, .module-ask-material {
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    .no-supply {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 23px;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .supplies {
        margin-top: 16px;
        .previous_label {
            margin: 32px 0 0 38px;
        }
        .supply_row {
            justify-content: space-between;
            margin-top:10px !important;
            margin-left:20px !important;
            background-color: white !important;
            width: calc(100% - 40px);
            border-radius: 12px !important;
            color:black !important;
            display:flex;
            .update {
                font-size: 12px;
            }
            .p-button {
                text-align: left;
                display: flex;
                justify-content: center;
                color:#222222;
            }
        }
    }

}

.site-tickets {
    margin-top: 16px;
    .site_ticket_row {
        justify-content: space-between;
        margin-top:10px !important;
        margin-left:20px !important;
        background-color: white !important;
        width: calc(100% - 40px);
        border-radius: 12px !important;
        color:black !important;
        display:flex;
        .update {
            font-size: 12px;
        }
        .p-button {
            text-align: left;
            display: flex;
            justify-content: center;
            color:#222222;
        }
    }
}

.attachments {
    margin-top: 16px;
    .attachment_row {
        margin-top:10px !important;
        margin-left:20px !important;
        background-color: white !important;
        width: calc(100% - 40px);
        border-radius: 12px !important;
        color:black !important;
        display:flex;
        .update {
            font-size: 12px;
        }
        .attachment-text {
            flex:2;
            display:flex;
            & > button {
                width: 100%;
            }
        }
        .p-button {
            text-align: left;
            display: flex;
            justify-content: center;
            color:#222222;
        }
    }
}

.deposit-tickets {
    margin-top: 16px;
    .deposit_ticket_row {
        justify-content: space-between;
        margin-top:10px !important;
        margin-left:20px !important;
        background-color: white !important;
        width: calc(100% - 40px);
        border-radius: 12px !important;
        color:black !important;
        display:flex;
        .update {
            font-size: 12px;
        }
        .p-button {
            text-align: left;
            display: flex;
            justify-content: center;
            color:#222222;
        }
    }
}

.site_comment {
    width:calc(100% - 40px);
    margin-left: 20px !important;
    min-height: 200px;
}

.site_validation_info {
    margin: 32px 32px 16px 32px;
}


.site_validation {
    margin-top: 20px !important;
    font-size: 20px !important;
    font-weight: bold !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 186px !important;
    padding: 20px !important;
    background: white !important;
    border-radius: 20px !important;
    margin: auto !important;
    svg {
        margin-right: 10px;
    }
    color: #222 !important;
    &.validated {
        color: #FBC601 !important;
    }
}