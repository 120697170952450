.form {
    .p-inputnumber.p-inputnumber {
        input {
            width: 100%;
        }
    }

    .form-actions {
        display: flex;
        flex-direction: row;
        margin: 2rem 0;
        gap: 2rem;
    }

    .form_error {
        color: #cc0000 !important;

        .p-accordion-header-link {
            color: #cc0000 !important;
        }
    }

    .form_validation {
        margin-top: 20px !important;
        font-size: 20px !important;
        font-weight: bold !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 186px !important;
        padding: 20px !important;
        background: white !important;
        border-radius: 20px !important;
        margin: auto !important;

        svg {
            margin-right: 10px;
        }

        color: #222 !important;

        &.sync {
            color: #2fa13e !important;
        }

        &.valid {
            color: #2fa13e !important;
        }

        &.invalid {
            color: #c00 !important;
        }
    }
}

.form-selector {
    margin-top: 36px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .button-form {
        margin-left: 20px !important;
        width: calc(100% - 40px) !important;
        padding: 10px 16px !important;
        background-color: white !important;
        color: #444444 !important;
        font-size: 14px !important;
        border-radius: 10px !important;
        margin-bottom: 8px !important;
        position: relative;

        svg {
            color: grey;
            margin-right: 8px;
            font-size: 18px;
            transform: translateY(-2px);
        }

        .arrow {
            color: black;
            position: absolute;
            right: 11px;
            top: 11px;
            font-size: 18px;
        }
    }
}

.tabEvent {
    position: relative;
    background: #f4f5f6;
    border-radius: 20px;
    margin-top: 20px;
    padding-top: 2px;
    min-height: 80px;
    padding-bottom: 1rem;

    .select_title {
        margin-left: 5%;
        color: rgba(0, 0, 0, 0.6);
        padding: 0px;
    }
}

.button-form-back {
    margin-left: 20px !important;
    width: calc(100% - 40px) !important;
    padding: 10px 16px !important;
    background-color: white !important;
    color: #444444 !important;
    font-size: 14px !important;
    border-radius: 10px !important;
    margin-bottom: 8px !important;
    position: relative;
    margin-top: 18px !important;

    svg {
        color: grey;
        margin-right: 8px;
        font-size: 18px;
        transform: translateY(-1px);
    }

}