@import "../files/style.scss";

.userSelection {
    z-index: 5;
    width: calc(100% - 40px);
    margin-left: 20px;
    .p-dropdown {
        width: 100%;
        border-radius: 6px;
        background-color: white;
        font-weight: bold;
    }
}

.module-page {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 54px;
    background-color: #f2f3f7;
    padding-bottom: 88px;
    overflow: auto;
    .no-supply {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-top: 23px;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    &.site-data {
        .type-selector {
            margin-top: 16px;
        }
    }
    .label {
        margin: 10px 20px;
        color: #8b8c91;
    }

    .panel {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-content: flex-start;
        margin-top: 16px;
        margin-bottom: 18px;
        > .p-button-link {
            color: #7d7d7d !important;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: unset;
            padding: 0;
            .icon {
                font-size: 24px;
            }
            &.selected {
                color: black !important;
            }
            &.missing {
                color: #b00020 !important;
            }
            &.p-disabled {
                background: transparent !important;
                color: #ccc !important;
            }
            &.selected::after {
                content: "";
                position: absolute;
                height: 6px;
                border-radius: 3px;
                width: 100%;
                background: #fbc601;
                bottom: -12px;
            }
        }
        .text {
            font-size: 12px;
        }
    }
    .material-info-wrapper {
        background-color: #f4f5f8;
        padding: 16px 8px;
        border-radius: 16px;
        margin: 0 24px;
        .p-dropdown,
        .p-input {
            background-color: white;
        }
    }
}
