.trash-create-dialog {
    .chooseMethod {
        margin:1rem auto;
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-content: center;
        align-items: center;
        flex-direction: row;
    }
    .ta-center {
        text-align: center;
    }
    .line {
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        background-color: white;
        position: relative;
        .counter-lines {
            div {
                display: flex;
                flex-direction: row;
                align-content: center;
                justify-content: space-between;
                align-items: center;
            }
            .counter {
                margin-top: 8px;
                .amount {
                    margin-left: 4px;
                }
            }
        }
        .delete {
            position: absolute;
            color: red !important;
            top: -20px;
            right: -20px;
            background: white !important;
            width: 40px !important;
            height: 40px !important;
            padding: 0;
            min-width: 0px;
            display: flex;
            align-content: center;
            justify-content: center;
            border-radius: 4rem;
        }
    }

    .label {
        text-align: center;
    }

    .sigCanvas {
        width:100%;
        .signatory {
            margin: 1rem;
        }
        margin: 0 auto;
        flex-direction: column;
        display: flex;
        canvas {
            border: 1px solid #222222;
            background-color: #dedede;
        }
    }

    .signature-actions {
        text-align: center;
        button {
            margin-top: 0.5rem;
        }
        button:first-child {
            margin-right: 8px;
        }
        button:last-child {
            margin-left: 8px;
        }
    }
}
