.module-dayoff {
    .dayoffs {
        .dayoff_row {
            margin-top:10px !important;
            margin-left:20px !important;
            background-color: white !important;
            width: calc(100% - 40px);
            border-radius: 12px !important;
            color:black !important;
            display:flex;
            padding:16px;
            justify-content: space-between;
            .update {
                font-size: 12px;
            }
            .p-button {
                text-align: left;
                display: flex;
                justify-content: center;
                color:#222222;
            }
        }
    }
}

.dayoff-create-button {
    position: absolute !important;
    bottom: 57px !important;
    right: 0px !important;
    margin: auto 10px 10px auto !important;
}

.dialog-dayoff {
    .label {
        margin-top: 20px;
    }
    .day-part {
        margin-top:10px;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
    }
    .dayrow {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        .dayoff-row {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            align-content: center;
            .total_dayoff_type {
                background-color: #dedede;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                margin-top: 10px;
            }
            .minus_add {
                flex-direction: row-reverse;
                display: flex;
                .p-button {
                    border: 1px solid #FBC601;
                    color: #FBC601;
                    min-width: 0;
                    padding: 8px;
                    margin: 0px;
                }
                .dayoff_used {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    line-height: 33px;
                    margin: auto 12px;
                    font-size: 16px;
                    width: 20px;
                }
            }
        }
    }
}
