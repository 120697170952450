body {
    background-image: url('../../../public/auth-illustration.jpg');
    background-size: cover;
    background-position: center;
    height:100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    .loading {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-items: center;
        justify-content: space-evenly;
    }
    #root {
        height:100%;
        .main-container {
            height:100%;
            width: 100%;;
            background-color: #f4f5f6;
            padding:0;
            position:relative;
            .disconnect {
                z-index:100;
                position:absolute;
                top: 7px;
                right: 5px;
                color:white;
                min-width: auto !important;
            }
            .goHome {
                z-index:100;
                position:absolute;
                top:-2px;
                left:-9px;
                img {
                    width:36px;
                    height:36px;
                }
            }
            .goBack {
                z-index:100;
                margin-top: 0px;
                width: auto;
                position:absolute;
                top:64px;
                left:0px;
                min-width: auto !important;
            }
        }
        .loading {
            position:fixed;
            top:0;
            left:0;
            width:100vw;
            height:100vh;
            z-index:999;
        }
    }
}